
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('barc_config.enlisted_university') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('barc_config.university_type')"
                            label-for="university_type"
                            >
                            <b-form-select
                                plain
                                v-model="search.university_type"
                                :options="universityTypeList"
                                id="university_type"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.country')"
                            label-for="country_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.country_id"
                                :options="countryList"
                                id="country_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('barc_config.university_name')"
                        label-for="university_name"
                        >
                        <v-select
                        name="university_name"
                        v-model="search.university_name"
                        label="text"
                        :options= enlistedUniversityLists
                        />
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('barc_config.enlisted_university_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(contact_person_mobile_bn)="data">
                                            {{ '০' + $n(data.item.contact_person_mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(university_name)="data">
                                          <span class="capitalize"> {{ data.item.university_name }} </span>
                                        </template>
                                        <template v-slot:cell(contact_person_name)="data">
                                          <span class="capitalize"> {{ data.item.contact_person_name }} </span>
                                        </template>
                                        <template v-slot:cell(address)="data">
                                          <span class="capitalize"> {{ data.item.address }} </span>
                                        </template>
                                        <template v-slot:cell(designation)="data">
                                          <span class="capitalize"> {{ data.item.designation }} </span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <!-- <b-button class="mr-1" title="View Details" v-b-modal.modal-details variant=" iq-bg-success border" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-details size="sm" @click="details(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-details" size="lg" :title="$t('barc_config.enlist_uni_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { enlistedUniversityList, enlistedUniversityStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        university_type: '0',
        country_id: '0',
        university_name: ''
      },
      rows: [],
      countryList: [],
      item: '',
      university_types: [
          {
              value: 1,
              text: 'Local',
              text_bn: 'স্থানীয়'
          },
          {
              value: 2,
              text: 'Foreign',
              text_bn: 'বিদেশী'
          }
      ]
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    // countryList: function () {
    //   const dataList = this.$store.state.commonObj.countryList.filter(item => item.status === 0)
    //   return dataList.map(item => {
    //         if (this.$i18n.locale === 'bn') {
    //             return { value: item.value, text: item.text_bn }
    //         } else {
    //             return { value: item.value, text: item.text }
    //         }
    //     })
    // },
    universityTypeList: function () {
        return this.listMapping(this.university_types)
    },
    enlistedUniversityLists: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.enlistedUniversityList.filter(item => item.status === 1)
      return this.listMapping(objectData)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('barc_config.enlisted_university') + ' ' + this.$t('globalTrans.entry') : this.$t('barc_config.enlisted_university') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('barc_config.university_type'), class: 'text-center' },
          { label: this.$t('globalTrans.country'), class: 'text-center' },
          { label: this.$t('barc_config.university_name'), class: 'text-center' },
          { label: this.$t('globalTrans.address'), class: 'text-center' },
          { label: this.$t('barc_config.contact_person_name'), class: 'text-center' },
          { label: this.$t('globalTrans.designation'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'university_type_name_bn' },
          { key: 'country_name_bn' },
          { key: 'university_name_bn' },
          { key: 'address_bn' },
          { key: 'contact_person_name_bn' },
          { key: 'designation_bn' },
          { key: 'contact_person_mobile_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'university_type_name' },
          { key: 'country_name' },
          { key: 'university_name' },
          { key: 'address' },
          { key: 'contact_person_name' },
          { key: 'designation' },
          { key: 'contact_person_mobile' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.university_type': function (newVal, oldVal) {
      this.countryList = this.getCountryList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    listMapping (data) {
        return data.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, enlistedUniversityStatus, item, 'incentive_grant', 'enlistedUniversityList')
    },
    loadData () {
      var universityId = ''
      if (this.search.university_name) {
          universityId = this.search.university_name.value
      }
      const params = Object.assign({}, this.search, { university_name: universityId, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, enlistedUniversityList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const countryList = this.$store.state.commonObj.countryList
      const universityTypeList = this.university_types

      const listData = data.map(item => {
        const countryObject = countryList.find(country => country.value === item.country_id)
        const universityTypeObject = universityTypeList.find(universityType => universityType.value === item.university_type)

        const countryData = { country_name: countryObject !== undefined ? countryObject.text_en : '', country_name_bn: countryObject !== undefined ? countryObject.text_bn : '' }
        const universityTypeData = { university_type_name: universityTypeObject !== undefined ? universityTypeObject.text : '', university_type_name_bn: universityTypeObject !== undefined ? universityTypeObject.text_bn : '' }

        return Object.assign({}, item, countryData, universityTypeData)
      })
      return listData
    },
    getCountryList (universityTypeId) {
        var dataList = []
        if (universityTypeId === 2) {
            dataList = this.$store.state.commonObj.countryList.filter(item => item.status === 0 && item.value !== 1)
        } else {
            dataList = this.$store.state.commonObj.countryList.filter(item => item.value === 1)
        }
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  }
}
</script>
