<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="University Name (En)" vid="university_name" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="university_name"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('barc_config.university_name_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="university_name"
                                                            v-model="enlistedUniversities.university_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="University Name (Bn)" vid="university_name_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="university_name_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('barc_config.university_name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="university_name_bn"
                                                            v-model="enlistedUniversities.university_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Abbreviation (En)" vid="abbreviation" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="abbreviation"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('barc_config.abbreviation_en') }} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="abbreviation"
                                                            v-model="enlistedUniversities.abbreviation"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Abbreviation (Bn)" vid="abbreviation_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="abbreviation_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('barc_config.abbreviation_bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="abbreviation_bn"
                                                            v-model="enlistedUniversities.abbreviation_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="University Address (En)" vid="address" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="address"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="address"
                                                            v-model="enlistedUniversities.address"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="University Address (Bn)" vid="address_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="address_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="address_bn"
                                                            v-model="enlistedUniversities.address_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Lattitude" vid="lattitude">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="lattitude"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.latitude')}}
                                                        </template>
                                                            <b-form-input
                                                            id="lattitude"
                                                            v-model="enlistedUniversities.lattitude"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="longitude" vid="longitude">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="longitude"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.longitude')}}
                                                        </template>
                                                            <b-form-input
                                                            id="longitude"
                                                            v-model="enlistedUniversities.longitude"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="University type" vid="university_type" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="university_type"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('barc_config.university_type') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="enlistedUniversities.university_type"
                                                            :options="universityTypeList"
                                                            id="university_type"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Country" vid="country_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="country_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('globalTrans.country') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="enlistedUniversities.country_id"
                                                            :options="countryList"
                                                            id="country_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3">
                                                            <h5 class="text-white text-center"> {{ $t('barc_config.add_contact_person_info') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Contact Person Name (En)" vid="contact_person_name" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="contact_person_name"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="contact_person_name"
                                                            v-model="enlistedUniversities.contact_person_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Contact Person Name (Bn)" vid="contact_person_name_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="contact_person_name_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="contact_person_name_bn"
                                                            v-model="enlistedUniversities.contact_person_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Designation (En)" vid="designation" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="designation"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.designation_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="designation"
                                                            v-model="enlistedUniversities.designation"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="designation_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.designation_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="designation_bn"
                                                            v-model="enlistedUniversities.designation_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Contact person mobile" vid="contact_person_mobile" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="contact_person_mobile"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            id="contact_person_mobile"
                                                            v-model="enlistedUniversities.contact_person_mobile"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="contact person email" vid="contact_person_email">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="contact_person_email"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.email')}}
                                                        </template>
                                                            <b-form-input
                                                            type="email"
                                                            id="contact_person_email"
                                                            v-model="enlistedUniversities.contact_person_email"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { enlistedUniversityStore, enlistedUniversityUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.enlistedUniversities = tmp
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            enlistedUniversities: {
                university_name: '',
                university_name_bn: '',
                address: '',
                address_bn: '',
                lattitude: '',
                longitude: '',
                university_type: '0',
                country_id: '0',
                contact_person_name: '',
                contact_person_name_bn: '',
                designation: '',
                designation_bn: '',
                contact_person_mobile: '',
                contact_person_email: ''
            },
            countryList: [],
            university_types: [
                {
                    value: 1,
                    text_en: 'Local',
                    text_bn: 'স্থানীয়'
                },
                {
                    value: 2,
                    text_en: 'Foreign',
                    text_bn: 'বিদেশী'
                }
            ]
        }
    },
    watch: {
        'enlistedUniversities.university_type': function (newVal, oldVal) {
            this.countryList = this.getCountryList(newVal)
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        universityTypeList: function () {
            return this.listMapping(this.university_types)
        }
    },
    methods: {
        getCountryList (universityTypeId) {
            var dataList = []
            if (universityTypeId === 1) {
                dataList = this.$store.state.commonObj.countryList.filter(item => item.status === 0 && item.value === 1)
            } else {
                dataList = this.$store.state.commonObj.countryList.filter(item => item.value !== 1)
            }
            return this.listMapping(dataList)
        },
        listMapping (data) {
            return data.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${enlistedUniversityUpdate}/${this.id}`, this.enlistedUniversities)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, enlistedUniversityStore, this.enlistedUniversities)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
