<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row class="mt-2">
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-modal id="modal-map" size="lg" :title="$t('irriPumpInformation.mapTitle')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <MapModal :latitude="latitude" :longitude="longitude"/>
      </b-modal>
    </b-container>
</template>
<script>
import MapModal from './MapModal'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  components: {
    MapModal
  },
  created () {
    this.enlistedUniversity = this.item
    this.latitude = this.item.lattitude
    this.longitude = this.item.longitude
  },
  data () {
    return {
      enlistedUniversity: {},
      latitude: '',
      longitude: ''
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('barc_config.university_type'), val: this.enlistedUniversity.university_type_name_bn, key1: this.$t('globalTrans.country'), val1: this.enlistedUniversity.country_name_bn },
          { key: this.$t('barc_config.university_name'), val: this.enlistedUniversity.university_name_bn, key1: this.$t('globalTrans.address'), val1: this.enlistedUniversity.address_bn },
          { key: this.$t('barc_config.abbreviation'), val: this.enlistedUniversity.abbreviation_bn },
          { key: this.$t('globalTrans.latitude'), val: this.$n(this.enlistedUniversity.lattitude), key1: this.$t('globalTrans.longitude'), val1: this.$n(this.enlistedUniversity.longitude) },
          { key: this.$t('barc_config.contact_person_name'), val: this.enlistedUniversity.contact_person_name_bn, key1: this.$t('globalTrans.designation'), val1: this.enlistedUniversity.designation_bn },
          { key: this.$t('globalTrans.mobile'), val: '০' + this.$n(this.enlistedUniversity.contact_person_mobile, { useGrouping: false }), key1: this.$t('globalTrans.email'), val1: this.enlistedUniversity.contact_person_email }
        ]
      } else {
        return [
          { key: this.$t('barc_config.university_type'), val: this.enlistedUniversity.university_type_name, key1: this.$t('globalTrans.country'), val1: this.enlistedUniversity.country_name },
          { key: this.$t('barc_config.university_name'), val: this.enlistedUniversity.university_name, key1: this.$t('globalTrans.address'), val1: this.enlistedUniversity.address },
          { key: this.$t('barc_config.abbreviation'), val: this.enlistedUniversity.abbreviation },
          { key: this.$t('globalTrans.latitude'), val: this.$n(this.enlistedUniversity.lattitude), key1: this.$t('globalTrans.longitude'), val1: this.$n(this.enlistedUniversity.longitude) },
          { key: this.$t('barc_config.contact_person_name'), val: this.enlistedUniversity.contact_person_name, key1: this.$t('globalTrans.designation'), val1: this.enlistedUniversity.designation },
          { key: this.$t('globalTrans.mobile'), val: '0' + this.$n(this.enlistedUniversity.contact_person_mobile, { useGrouping: false }), key1: this.$t('globalTrans.email'), val1: this.enlistedUniversity.contact_person_email }
        ]
      }
    }
  },
  methods: {
    mapModalOpen (item) {
      this.latitude = item.latitude
      this.longitude = item.longitude
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
