<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div class="map">
                          <iframe width="100%" height="370" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="'https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s' + lat + ',' + lan + '!6i15'"></iframe>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['latitude', 'longitude'],
  data () {
    return {
      showInfo: false,
      lat: this.latitude,
      lan: this.longitude
    }
  }
}
</script>
